import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Masthead from "../components/Masthead"

const SuccessPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Masthead 
      title="Success!"
      content="One of our team members will be in contact with you to build your requested dataset."
    />
  </Layout>
)

export default SuccessPage
